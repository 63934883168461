import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import YouTube from "../components/youtube";


export default () => {

  return (
    <Layout>
    <div style={{ 'position': 'relative' }}>
      <Link to='/oer-das-kinderhauslied' className="closeX"><span>X</span></Link>
      <h2>Mixing-Projekt zum <i>Kinderhauslied</i></h2>
    </div>
    <hr style={{ "margin": "40px 0" }} />
    <p>
      Ulrich Kaiser, <span class="i">Das Kinderhauslied</span> (=&nbsp;OpenBook&nbsp;10), Karlsfeld 2015.<br /><br/>
      <a href="http://www.scribus.net/canvas/Scribus" target="_blank"><img src="/Images/scribusLogo.png" /></a>&nbsp;<img src="/Images/CC_BY-SA.png" /><br /><br/>
        Dieses OpenBook wurde mit der Opensource-Software <a href="http://www.scribus.net/canvas/Scribus" target="_blank">Scribus</a> unter
      <a href="https://creativecommons.org/licenses/by/3.0/de/">Creative Commons BY-SA</a> veröffentlicht.
    </p>
    <audio style={{ 'margin': '10px 0' }} src="/data/audio/Kinderhauslied/kaiser-kinderhauslied.mp3" controls id="kinderhauslied">
      <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
    </audio>
    <p>
      Das Kinderhauslied. Musik, Text, Aufnahme und Schnitt: Ulrich Kaiser, professionell gemixt und gemastert von <a href="http://www.daniel-scholz.de/" target="_blank">Daniel Scholz</a>. Das Kinderhauslied ist zum 50jährigen Bestehen des <a href="http://www.erzbistum-muenchen.de/Pfarrei/Page010455.aspx" target="_blank">Kinderhauses Sankt Josef</a> komponiert worden (<a href="/data/audio/kinderhauslied/kaiser-kinderhauslied.mp3" target="_blank">Sounddatei</a>, <a href="/publicfiles/kinderhauslied_cd-layout.pdf" target="_blank">CD-Front- und Backcover</a>). Ein Video der Uraufführung des Kinderhausliedes finden sie <Link to="/filme">hier</Link>.<br /><br />
     Lernen Sie die Stimmen des Kinderhausliedes (Melodie 1, Melodie 2, Flöte, Klarinette, Violine, Viola, Trompete, Gitarre, Klavier, E-Bass und Schlagzeug) kennen, indem Sie für die vierte Strophe die Lautstärke an den einzelnen Tracks verändern. Wenn Sie sich Einzelspuren angehört haben und die Tracks nicht mehr synchron gespielt werden, verwenden Sie die Schaltflächen 'Reset' und 'Start / Stopp' zum Synchronisieren. Die Lautstärke lässt sich an den Audioplayern für jeden Kanal einzeln regeln und mit dem Slider für alle Spuren gleichzeitig. Achten Sie darauf, dass die Sounddateien geladen sind, bevor es losgeht. Viel Spaß beim Mixen des Kinderhausliedes...
    </p>
    <div>
      <Link to="/oer-das-kinderhauslied"><img src="/Images/kaiser_openBookKinderhauslied.png" alt="" /></Link>
    </div>
    <div style={{ 'marginTop': '40px', 'display': 'flex' }}>
      <button style={{ 'marginRight': '10px' }} id="startStoppButton" onClick={() => onStartStoppClick()}>Start / Stopp</button>       
      <button style={{ 'marginRight': '10px' }} id="resetButton" onClick={() => onResetClick()}>Reset</button>       
      <input style={{ 'marginTop': '4px' }} id="volumeButton" onChange={() => onVolumeChange()} type="range" step="1" />
    </div>
    <hr style={{ "margin": "40px 0" }} />
    <div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/Kinderhauslied/Gesang1.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Kinderhauslied</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/Kinderhauslied/Gesang2.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">zweite Stimme</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/Kinderhauslied/Floete.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Flöte</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/Kinderhauslied/Klarinette.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Klarinette</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/Kinderhauslied/Violine.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Violine</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/Kinderhauslied/Viola.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Viola</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/Kinderhauslied/Trompete.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Trompete</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/Kinderhauslied/Gitarre.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Gitarre</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/Kinderhauslied/Klavier.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Klavier</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/Kinderhauslied/Bass.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Bass</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/Kinderhauslied/Drums.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Schlagzeug</p>
      </div>
    </div>
    <Helmet>
      <title>OER | Ulrich Kaiser - Mixing Projekt</title>
      <meta name="description" content="Hier finden Sie ein Mixing-Projekt zum Studieren der Komposition Kinderhauslied von Ulrich Kaiser sowie zum Anhören der der Einzelstimmen." />
      <meta name="keywords" content="Projekt, Kinderhauslied, OER, Open Educational Resources, OpenBooks" />
      <meta name="copyright" content="Ulrich Kaiser, 2011" />
    </Helmet>
  </Layout>
  )
}

